// src/components/SendNewsletter.js
import React, { useState } from "react";
import axios from "axios";
import "./SendNewsletter.scss";

const SendNewsletter = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(
        "https://backend.himalayafairreisen.com/email/send-newsletter",
        {
          subject,
          message,
        }
      );
      setStatus("Newsletter sent successfully");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error sending newsletter:", error);
      setStatus("Failed to send newsletter");
    }
  };

  return (
    <div className="send-newsletter">
      <h2>Send Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Send Newsletter</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default SendNewsletter;
