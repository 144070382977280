import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaSignOutAlt,
  FaHome,
  FaBox,
  FaRoute,
  FaRunning,
  FaPlaneDeparture,
  FaEnvelopeOpenText,
  FaImages,
  FaImage,
} from "react-icons/fa";

import "./Sidebar.scss";

function Sidebar() {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/login");
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-top">
        <h2 className="admin-panel">Admin Panel</h2>
        <div className="hr-line"></div>
        <nav>
          <div className="section-label">Main</div>
          <ul>
            <li>
              <NavLink to="/admin/dashboard" activeClassName="active">
                <FaHome className="icon" />
                <p className="names-cat">Dashboard</p>
              </NavLink>
            </li>
          </ul>
          <div className="section-label">General</div>
          <ul>
            {/* <li>
              <NavLink to="/admin/add-destination" activeClassName="active">
                <FaMapMarkerAlt className="icon" />
                <p className="names-cat">Destination</p>
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/admin/add-package" activeClassName="active">
                <FaBox className="icon" />
                <p className="names-cat">Package</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-trip" activeClassName="active">
                <FaRoute className="icon" />
                <p className="names-cat">Trips</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-activites" activeClassName="active">
                <FaRunning className="icon" />
                <p className="names-cat">Activities</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-travel" activeClassName="active">
                <FaPlaneDeparture className="icon" />
                <p className="names-cat">Travel</p>
              </NavLink>
            </li>

            <li>
              <NavLink to="/admin/add-newsletter" activeClassName="active">
                <FaEnvelopeOpenText className="icon" />
                <p className="names-cat">News Letter</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-album" activeClassName="active">
                <FaImages className="icon" />
                <p className="names-cat">Album</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/add-photo" activeClassName="active">
                <FaImage className="icon" />
                <p className="names-cat">Photo</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt className="icon" />
        <span className="logout-name">Logout</span>
      </button>
    </div>
  );
}

export default Sidebar;
