import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FilterTravels.scss"; // Create a corresponding SCSS file for styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function FilterTravels() {
  const [travels, setTravels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [maxDays, setMaxDays] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [groupTravel, setGroupTravel] = useState(false);
  const [hotelAccommodation, setHotelAccommodation] = useState(false);

  useEffect(() => {
    fetchCategories(); // Fetch categories when the component mounts
  }, []);

  // Fetch all categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      alert("Failed to fetch categories. Please try again.");
    }
  };

  // Fetch filtered travels based on selected filters
  const fetchFilteredTravels = async () => {
    try {
      const params = {
        category: selectedCategory,
        days: maxDays,
        price: maxPrice,
        difficulty_level: difficultyLevel,
        group_travel: groupTravel,
        hotel_accommodation: hotelAccommodation,
      };

      const response = await axios.get(`${API_URL}/travel`, { params });
      setTravels(response.data);
      console.log("Fetched filtered travels:", response.data);
    } catch (error) {
      console.error("Error fetching filtered travels:", error.message);
      alert("Failed to fetch filtered travels. Please try again.");
    }
  };

  // Handle filter form submission
  const handleFilterSubmit = (event) => {
    event.preventDefault();
    fetchFilteredTravels();
  };

  return (
    <div className="filter-travels-main">
      <h1 className="filter-title">Filter Travels</h1>
      <form onSubmit={handleFilterSubmit} className="filter-form">
        <div>
          <label>Category:</label>
          <select
            className="input-filter"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select a Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Max Days:</label>
          <input
            className="input-filter"
            type="number"
            value={maxDays}
            onChange={(e) => setMaxDays(e.target.value)}
            placeholder="Max Days"
          />
        </div>
        <div>
          <label>Max Price:</label>
          <input
            className="input-filter"
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            placeholder="Max Price"
          />
        </div>
        <div>
          <label>Difficulty Level (1-5):</label>
          <input
            className="input-filter"
            type="number"
            min="1"
            max="5"
            value={difficultyLevel}
            onChange={(e) => setDifficultyLevel(e.target.value)}
            placeholder="Difficulty Level"
          />
        </div>
        <div>
          <label>Group Travel:</label>
          <input
            className="input-checkbox"
            type="checkbox"
            checked={groupTravel}
            onChange={(e) => setGroupTravel(e.target.checked)}
          />
        </div>
        <div>
          <label>Hotel Accommodation:</label>
          <input
            className="input-checkbox"
            type="checkbox"
            checked={hotelAccommodation}
            onChange={(e) => setHotelAccommodation(e.target.checked)}
          />
        </div>
        <button className="button-filter" type="submit">
          Apply Filters
        </button>
      </form>

      <div className="travels-container">
        <h2 className="title-travel-result">Filtered Travels</h2>
        {travels && travels.length > 0 ? (
          travels.map((travel) => (
            <div key={travel.id} className="travel-card">
              <div className="travel-info">
                <img
                  className="travel-image"
                  src={`${API_URL}/travel/${travel.id}/image`} // Ensure this matches the backend route
                  alt={travel.title}
                />
                <div>
                  <span className="travel-name">{travel.title}</span>
                  <p className="travel-description">{travel.description}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No travels found based on the selected filters.</p>
        )}
      </div>
    </div>
  );
}

export default FilterTravels;
