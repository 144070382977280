import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Package.scss"; // Make sure this SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddPackage() {
  const [country, setCountry] = useState("Nepal");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [activity, setActivity] = useState("");
  const [map, setMap] = useState("");
  const [included, setIncluded] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [activities, setActivities] = useState([]);
  const [packages, setPackages] = useState([]);
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPackages();
    fetchActivities();
  }, []);

  // Fetch all packages
  const fetchPackages = async () => {
    try {
      const response = await axios.get(`${API_URL}/packages`);
      setPackages(response.data);
    } catch (error) {
      console.error("Error fetching packages:", error.message);
      alert("Failed to fetch packages. Please try again.");
    }
  };

  // Fetch all activities
  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${API_URL}/activities`);
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error.message);
    }
  };

  // Handle form submission for adding/updating packages
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("country", country);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("itinerary", itinerary);
    formData.append("price", price);
    formData.append("image", image);
    formData.append("activity", activity);
    formData.append("map", map);
    formData.append("included", included);
    formData.append("not_included", notIncluded);

    try {
      if (editId) {
        await axios.put(`${API_URL}/packages/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Package updated successfully!");
      } else {
        await axios.post(`${API_URL}/packages`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Package added successfully!");
      }
      fetchPackages();
      resetForm();
    } catch (error) {
      console.error("Error saving package:", error.message);
      alert("Failed to save package. Please try again.");
    }
  };

  // Handle delete package
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/packages/${id}`);
      fetchPackages();
    } catch (error) {
      console.error("Error deleting package:", error.message);
      alert("There was an error deleting the package!");
    }
  };

  // Handle edit package
  const handleEdit = (packageItem) => {
    setCountry(packageItem.country);
    setTitle(packageItem.title);
    setDescription(packageItem.description);
    setItinerary(packageItem.itinerary);
    setPrice(packageItem.price);
    setActivity(packageItem.activity);
    setMap(packageItem.map);
    setIncluded(packageItem.included);
    setNotIncluded(packageItem.not_included);
    setEditId(packageItem.id);
  };

  // Reset form fields
  const resetForm = () => {
    setCountry("Nepal");
    setTitle("");
    setDescription("");
    setItinerary("");
    setPrice("");
    setImage(null);
    setActivity("");
    setMap("");
    setIncluded("");
    setNotIncluded("");
    setEditId(null);
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="package-main-div">
      <div className="add-package">
        <h1 className="title-package">
          {editId ? "Update Package" : "Add Package"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Country:</label>
            <select
              className="input-package"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
              <option value="Nepal">Nepal</option>
              <option value="India">India</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Tibet">Tibet</option>
            </select>
          </div>
          <div>
            <label>Title:</label>
            <input
              className="input-package"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Package Title"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-package"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Package Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Itinerary:</label>
            <textarea
              className="input-package"
              value={itinerary}
              onChange={(e) => setItinerary(e.target.value)}
              placeholder="Package Itinerary"
              required
            ></textarea>
          </div>
          <div>
            <label>Price:</label>
            <input
              className="input-package"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Package Price"
              required
            />
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <div>
            <label>Activity:</label>
            <select
              className="input-package"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              required
            >
              <option value="">Select an Activity</option>
              {activities.map((activityItem) => (
                <option key={activityItem.id} value={activityItem.title}>
                  {activityItem.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Map:</label>
            <input
              className="input-package"
              type="text"
              value={map}
              onChange={(e) => setMap(e.target.value)}
              placeholder="Map URL"
              required
            />
          </div>
          <div>
            <label>Included:</label>
            <textarea
              className="input-package"
              value={included}
              onChange={(e) => setIncluded(e.target.value)}
              placeholder="Included Items"
              required
            ></textarea>
          </div>
          <div>
            <label>Not Included:</label>
            <textarea
              className="input-package"
              value={notIncluded}
              onChange={(e) => setNotIncluded(e.target.value)}
              placeholder="Not Included Items"
              required
            ></textarea>
          </div>
          <button className="button-add-package" type="submit">
            {editId ? "Update Package" : "Add Package"}
          </button>
        </form>
      </div>

      <div className="packages-container">
        <h2 className="title-package-result">Packages List</h2>
        {packages && packages.length > 0 ? (
          packages.map((packageItem) => (
            <div key={packageItem.id} className="package-card">
              <div className="package-info">
                <img
                  className="package-image"
                  src={`${API_URL}/packages/${packageItem.id}/image`} // Ensure this matches the backend route
                  alt={packageItem.title}
                />
                <div>
                  <span className="package-name">{packageItem.title}</span>
                  {/* <p className="package-description">
                    {packageItem.description}
                  </p> */}
                </div>
              </div>
              <div className="package-actions">
                <button onClick={() => handleEdit(packageItem)}>Edit</button>
                <button onClick={() => handleDelete(packageItem.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No packages found.</p>
        )}
      </div>
    </div>
  );
}

export default AddPackage;
