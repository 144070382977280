import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.scss";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const adminUsername = process.env.REACT_APP_ADMIN_USERNAME;
    const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

    if (username === adminUsername && password === adminPassword) {
      localStorage.setItem("auth", "true");
      navigate("/admin");
    } else {
      alert("Invalid credentials");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login-main-div">
      <div className="login-container">
        <h4 className="main-text">Himalayan Fair Reisen</h4>
        <p className="sub-main-text">Sign in to your account to continue</p>

        <form onSubmit={handleLogin}>
          <div className="username-main-div">
            <div className="label-name">
              <label className="username-label">
                Username <span className="required-asterisk">*</span>
              </label>
            </div>
            <input
              className="main-input"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="password-main-div">
            <div className="label-password">
              <label className="password-label">
                Password <span className="required-asterisk">*</span>
              </label>
            </div>
            <div className="password-input-container">
              <input
                className="main-input"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="toggle-password" onClick={toggleShowPassword}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          {/* <div className="additional-options">
          <div className="remember-me">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <label>Remember Me</label>
          </div>
          <div className="forgot-password">
            <a href="/forgot-password">Forgot Password?</a>
          </div>
        </div> */}
          <button type="submit">Login</button>
        </form>
        {/* <p className="signup-text">
          Don't have an account? <a href="/signup">Sign Up</a>
        </p> */}
      </div>
    </div>
  );
}

export default Login;
