import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Activities.scss"; // Make sure the SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddActivities() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);
  const [activities, setActivities] = useState([]);
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivities();
  }, []);

  // Fetch all activities
  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${API_URL}/activities`);
      setActivities(response.data);
      console.log("Fetched activities:", response.data);
    } catch (error) {
      console.error("Error fetching activities:", error.message);
      alert("Failed to fetch activities. Please try again.");
    }
  };

  // Handle form submission for adding/updating activities
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("photo", photo);

    try {
      if (editId) {
        await axios.put(`${API_URL}/activities/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Activity updated successfully!");
      } else {
        await axios.post(`${API_URL}/activities`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Activity added successfully!");
      }
      fetchActivities();
      resetForm();
    } catch (error) {
      console.error("Error saving activity:", error.message);
      alert("Failed to save activity. Please try again.");
    }
  };

  // Handle delete activity
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/activities/${id}`);
      fetchActivities();
    } catch (error) {
      console.error("Error deleting activity:", error.message);
      alert("There was an error deleting the activity!");
    }
  };

  // Handle edit activity
  const handleEdit = (activity) => {
    setTitle(activity.title);
    setDescription(activity.description);
    setEditId(activity.id);
  };

  // Reset form fields
  const resetForm = () => {
    setTitle("");
    setDescription("");
    setPhoto(null);
    setEditId(null);
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div className="activity-main-div">
      <div className="add-activity">
        <h1 className="title-activity">
          {editId ? "Update Activity" : "Add Activity"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Title:</label>
            <input
              className="input-activity"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Activity Title"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-activity"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Activity Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Photo:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <button className="button-add-activity" type="submit">
            {editId ? "Update Activity" : "Add Activity"}
          </button>
        </form>
      </div>

      <div className="activities-container">
        <h2 className="title-activity-result">Activities List</h2>
        {activities && activities.length > 0 ? (
          activities.map((activity) => (
            <div key={activity.id} className="activity-card">
              <div className="activity-info">
                <img
                  className="activity-image"
                  src={`${API_URL}/activities/${activity.id}/photo`} // Ensure this matches the backend route
                  alt={activity.title}
                />
                <div>
                  <span className="activity-name">{activity.title}</span>
                  {/* <p className="activity-description">{activity.description}</p> */}
                </div>
              </div>
              <div className="activity-actions">
                <button onClick={() => handleEdit(activity)}>Edit</button>
                <button onClick={() => handleDelete(activity.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No activities found.</p>
        )}
      </div>
    </div>
  );
}

export default AddActivities;
