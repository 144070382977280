import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Photos.scss"; // Ensure this SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddPhoto() {
  const [albumId, setAlbumId] = useState("");
  const [heading, setHeading] = useState("");
  const [image, setImage] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchPhotos();
    fetchAlbums();
  }, []);

  // Fetch all photos
  const fetchPhotos = async () => {
    try {
      const response = await axios.get(`${API_URL}/photos`);
      setPhotos(response.data);
      console.log("Fetched photos:", response.data);
    } catch (error) {
      console.error("Error fetching photos:", error.message);
      alert("Failed to fetch photos. Please try again.");
    }
  };

  // Fetch all albums
  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      setAlbums(response.data);
    } catch (error) {
      console.error("Error fetching albums:", error.message);
      alert("Failed to fetch albums. Please try again.");
    }
  };

  // Handle form submission for adding/updating photos
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("album_id", albumId);
    formData.append("heading", heading);
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/photos/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Photo updated successfully!");
      } else {
        await axios.post(`${API_URL}/photos`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Photo added successfully!");
      }
      fetchPhotos();
      resetForm();
    } catch (error) {
      console.error("Error saving photo:", error.message);
      alert("Failed to save photo. Please try again.");
    }
  };

  // Handle delete photo
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/photos/${id}`);
      fetchPhotos();
    } catch (error) {
      console.error("Error deleting photo:", error.message);
      alert("There was an error deleting the photo!");
    }
  };

  // Handle edit photo
  const handleEdit = (photo) => {
    setAlbumId(photo.album_id);
    setHeading(photo.heading);
    setEditId(photo.id);
  };

  // Reset form fields
  const resetForm = () => {
    setAlbumId("");
    setHeading("");
    setImage(null);
    setEditId(null);
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="photo-main-div">
      <div className="add-photo">
        <h1 className="title-photo">{editId ? "Update Photo" : "Add Photo"}</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Album:</label>
            <select
              className="input-photo"
              value={albumId}
              onChange={(e) => setAlbumId(e.target.value)}
              required
            >
              <option value="">Select an Album</option>
              {albums.map((album) => (
                <option key={album.id} value={album.id}>
                  {album.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Heading:</label>
            <input
              className="input-photo"
              type="text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Photo Heading"
              required
            />
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <button className="button-add-photo" type="submit">
            {editId ? "Update Photo" : "Add Photo"}
          </button>
        </form>
      </div>

      <div className="photos-container">
        <h2 className="title-photo-result">Photos List</h2>
        {photos && photos.length > 0 ? (
          photos.map((photo) => (
            <div key={photo.id} className="photo-card">
              <div className="photo-info">
                <img
                  className="photo-image"
                  src={`${API_URL}/photos/${photo.id}/image`} // Ensure this matches the backend route
                  alt={photo.heading}
                />
                <div>
                  <span className="photo-heading">{photo.heading}</span>
                </div>
              </div>
              <div className="photo-actions">
                <button onClick={() => handleEdit(photo)}>Edit</button>
                <button onClick={() => handleDelete(photo.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No photos found.</p>
        )}
      </div>
    </div>
  );
}

export default AddPhoto;
