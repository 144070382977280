import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Destination.scss"; // Ensure this SCSS file is updated with the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Update with your API URL

function AddDestination() {
  const [country, setCountry] = useState("Nepal");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [image, setImage] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [map, setMap] = useState("");
  const [days, setDays] = useState("");
  const [maximumAltitude, setMaximumAltitude] = useState("");
  const [included, setIncluded] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [destinations, setDestinations] = useState([]);
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDestinations();
    fetchRoutes();
  }, []);

  // Fetch all destinations
  const fetchDestinations = async () => {
    try {
      const response = await axios.get(`${API_URL}/destinations`);
      setDestinations(response.data);
      console.log("Fetched destinations:", response.data);
    } catch (error) {
      console.error("Error fetching destinations:", error.message);
      alert("Failed to fetch destinations. Please try again.");
    }
  };

  // Fetch all routes
  const fetchRoutes = async () => {
    try {
      const response = await axios.get(`${API_URL}/trekroutes`);
      const uniqueRoutes = Array.from(
        new Set(response.data.map((route) => route.title))
      ).map((title) => {
        return response.data.find((route) => route.title === title);
      });
      console.log("Fetched routes:", uniqueRoutes);
      setRoutes(uniqueRoutes);
    } catch (error) {
      console.error("Error fetching routes:", error.message);
    }
  };

  // Handle form submission for adding/updating destinations
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("country", country);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("itinerary", itinerary);
    formData.append("image", image);
    formData.append("routes", selectedRoute);
    formData.append("map", map);
    formData.append("days", days);
    formData.append("maximum_altitude", maximumAltitude);
    formData.append("included", included);
    formData.append("not_included", notIncluded);

    try {
      if (editId) {
        await axios.put(`${API_URL}/destinations/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Destination updated successfully!");
      } else {
        await axios.post(`${API_URL}/destinations`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Destination added successfully!");
      }
      fetchDestinations();
      resetForm();
    } catch (error) {
      console.error("Error saving destination:", error.message);
      alert("Failed to save destination. Please try again.");
    }
  };

  // Handle delete destination
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/destinations/${id}`);
      fetchDestinations();
    } catch (error) {
      console.error("Error deleting destination:", error.message);
      alert("There was an error deleting the destination!");
    }
  };

  // Handle edit destination
  const handleEdit = (destination) => {
    setCountry(destination.country);
    setTitle(destination.title);
    setDescription(destination.description);
    setItinerary(destination.itinerary);
    setSelectedRoute(destination.routes);
    setMap(destination.map);
    setDays(destination.days);
    setMaximumAltitude(destination.maximum_altitude);
    setIncluded(destination.included);
    setNotIncluded(destination.not_included);
    setEditId(destination.id);
  };

  // Reset form fields
  const resetForm = () => {
    setCountry("Nepal");
    setTitle("");
    setDescription("");
    setItinerary("");
    setImage(null);
    setSelectedRoute("");
    setMap("");
    setDays("");
    setMaximumAltitude("");
    setIncluded("");
    setNotIncluded("");
    setEditId(null);
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="destination-main-div">
      <div className="add-destination">
        <h1 className="title-destination">
          {editId ? "Update Destination" : "Add Destination"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Country:</label>
            <select
              className="input-destination"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            >
              <option value="Nepal">Nepal</option>
              <option value="India">India</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Tibet">Tibet</option>
            </select>
          </div>
          <div>
            <label>Title:</label>
            <input
              className="input-destination"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Destination Title"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-destination"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Destination Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Itinerary:</label>
            <textarea
              className="input-destination"
              value={itinerary}
              onChange={(e) => setItinerary(e.target.value)}
              placeholder="Destination Itinerary"
              required
            ></textarea>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <div>
            <label>Route:</label>
            <select
              className="input-destination"
              value={selectedRoute}
              onChange={(e) => setSelectedRoute(e.target.value)}
              required
            >
              <option value="">Select a Route</option>
              {routes.map((route) => (
                <option key={route.id} value={route.title}>
                  {route.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Map:</label>
            <input
              className="input-destination"
              type="text"
              value={map}
              onChange={(e) => setMap(e.target.value)}
              placeholder="Map URL"
              required
            />
          </div>
          <div>
            <label>Days:</label>
            <input
              className="input-destination"
              type="number"
              value={days}
              onChange={(e) => setDays(e.target.value)}
              placeholder="Number of Days"
              required
            />
          </div>
          <div>
            <label>Maximum Altitude:</label>
            <input
              className="input-destination"
              type="number"
              value={maximumAltitude}
              onChange={(e) => setMaximumAltitude(e.target.value)}
              placeholder="Maximum Altitude"
              required
            />
          </div>
          <div>
            <label>Included:</label>
            <textarea
              className="input-destination"
              value={included}
              onChange={(e) => setIncluded(e.target.value)}
              placeholder="Included Items"
              required
            ></textarea>
          </div>
          <div>
            <label>Not Included:</label>
            <textarea
              className="input-destination"
              value={notIncluded}
              onChange={(e) => setNotIncluded(e.target.value)}
              placeholder="Not Included Items"
              required
            ></textarea>
          </div>
          <button className="button-add-destination" type="submit">
            {editId ? "Update Destination" : "Add Destination"}
          </button>
        </form>
      </div>

      <div className="destinations-container">
        <h2 className="title-destination-result">Destinations List</h2>
        {destinations && destinations.length > 0 ? (
          destinations.map((destination) => (
            <div key={destination.id} className="destination-card">
              <div className="destination-info">
                <img
                  className="destination-image"
                  src={`${API_URL}/destinations/${destination.id}/image`} // Ensure this matches the backend route
                  alt={destination.title}
                />
                <div>
                  <span className="destination-name">{destination.title}</span>
                  {/* <p className="destination-description">
                    {destination.description}
                  </p> */}
                </div>
              </div>
              <div className="destination-actions">
                <button onClick={() => handleEdit(destination)}>Edit</button>
                <button onClick={() => handleDelete(destination.id)}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No destinations found.</p>
        )}
      </div>
    </div>
  );
}

export default AddDestination;
