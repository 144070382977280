import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Albums.scss"; // Ensure this SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddAlbum() {
  const [name, setName] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchAlbums();
  }, []);

  // Fetch all albums
  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      setAlbums(response.data);
      console.log("Fetched albums:", response.data);
    } catch (error) {
      console.error("Error fetching albums:", error.message);
      alert("Failed to fetch albums. Please try again.");
    }
  };

  // Handle form submission for adding/updating albums
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    if (coverImage) {
      formData.append("cover_image", coverImage);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/albums/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Album updated successfully!");
      } else {
        await axios.post(`${API_URL}/albums`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Album added successfully!");
      }
      fetchAlbums();
      resetForm();
    } catch (error) {
      console.error("Error saving album:", error.message);
      alert("Failed to save album. Please try again.");
    }
  };

  // Handle delete album
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/albums/${id}`);
      fetchAlbums();
    } catch (error) {
      console.error("Error deleting album:", error.message);
      alert("There was an error deleting the album!");
    }
  };

  // Handle edit album
  const handleEdit = (album) => {
    setName(album.name);
    setEditId(album.id);
  };

  // Reset form fields
  const resetForm = () => {
    setName("");
    setCoverImage(null);
    setEditId(null);
  };

  // Handle file change for cover image upload
  const handleFileChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  return (
    <div className="album-main-div">
      <div className="add-album">
        <h1 className="title-album">{editId ? "Update Album" : "Add Album"}</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              className="input-album"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Album Name"
              required
            />
          </div>
          <div>
            <label>Cover Image:</label>
            <input type="file" onChange={handleFileChange} required={!editId} />
          </div>
          <button className="button-add-album" type="submit">
            {editId ? "Update Album" : "Add Album"}
          </button>
        </form>
      </div>

      <div className="albums-container">
        <h2 className="title-album-result">Albums List</h2>
        {albums && albums.length > 0 ? (
          albums.map((album) => (
            <div key={album.id} className="album-card">
              <div className="album-info">
                <img
                  className="album-image"
                  src={`${API_URL}/albums/${album.id}/cover`} // Ensure this matches the backend route
                  alt={album.name}
                />
                <div>
                  <span className="album-name">{album.name}</span>
                </div>
              </div>
              <div className="album-actions">
                <button onClick={() => handleEdit(album)}>Edit</button>
                <button onClick={() => handleDelete(album.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No albums found.</p>
        )}
      </div>
    </div>
  );
}

export default AddAlbum;
