import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Trips.scss"; // Ensure this SCSS file matches the new styles

const API_URL = "https://backend.himalayafairreisen.com"; // Set your API URL here

function AddTrip() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [tourGuideName, setTourGuideName] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [map, setMap] = useState("");
  const [included, setIncluded] = useState("");
  const [notIncluded, setNotIncluded] = useState("");
  const [image, setImage] = useState(null);
  const [trips, setTrips] = useState([]);
  const [editId, setEditId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrips();
  }, []);

  // Fetch all trips
  const fetchTrips = async () => {
    try {
      const response = await axios.get(`${API_URL}/trips`);
      setTrips(response.data);
      console.log("Fetched trips:", response.data);
    } catch (error) {
      console.error("Error fetching trips:", error.message);
      alert("Failed to fetch trips. Please try again.");
    }
  };

  // Handle form submission for adding/updating trips
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("tour_guide_name", tourGuideName);
    formData.append("itinerary", itinerary);
    formData.append("map", map);
    formData.append("included", included);
    formData.append("not_included", notIncluded);
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/trips/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Trip updated successfully!");
      } else {
        await axios.post(`${API_URL}/trips`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Trip added successfully!");
      }
      fetchTrips();
      resetForm();
    } catch (error) {
      console.error("Error saving trip:", error.message);
      alert("Failed to save trip. Please try again.");
    }
  };

  // Handle delete trip
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/trips/${id}`);
      fetchTrips();
    } catch (error) {
      console.error("Error deleting trip:", error.message);
      alert("There was an error deleting the trip!");
    }
  };

  // Handle edit trip
  const handleEdit = (trip) => {
    setName(trip.name);
    setDescription(trip.description);
    setPrice(trip.price);
    setTourGuideName(trip.tour_guide_name);
    setItinerary(trip.itinerary);
    setMap(trip.map);
    setIncluded(trip.included);
    setNotIncluded(trip.not_included);
    setEditId(trip.id);
  };

  // Reset form fields
  const resetForm = () => {
    setName("");
    setDescription("");
    setPrice("");
    setTourGuideName("");
    setItinerary("");
    setMap("");
    setIncluded("");
    setNotIncluded("");
    setImage(null);
    setEditId(null);
  };

  // Handle file change for image upload
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="trip-main-div">
      <div className="add-trip">
        <h1 className="title-trip">{editId ? "Update Trip" : "Add Trip"}</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input
              className="input-trip"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Trip Name"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-trip"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Trip Description"
              required
            ></textarea>
          </div>
          <div>
            <label>Price:</label>
            <input
              className="input-trip"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Trip Price"
              required
            />
          </div>
          <div>
            <label>Tour Guide Name:</label>
            <input
              className="input-trip"
              type="text"
              value={tourGuideName}
              onChange={(e) => setTourGuideName(e.target.value)}
              placeholder="Tour Guide Name"
              required
            />
          </div>
          <div>
            <label>Itinerary:</label>
            <textarea
              className="input-trip"
              value={itinerary}
              onChange={(e) => setItinerary(e.target.value)}
              placeholder="Trip Itinerary"
              required
            ></textarea>
          </div>
          <div>
            <label>Map:</label>
            <input
              className="input-trip"
              type="text"
              value={map}
              onChange={(e) => setMap(e.target.value)}
              placeholder="Map URL"
              required
            />
          </div>
          <div>
            <label>Included:</label>
            <textarea
              className="input-trip"
              value={included}
              onChange={(e) => setIncluded(e.target.value)}
              placeholder="Included Items"
              required
            ></textarea>
          </div>
          <div>
            <label>Not Included:</label>
            <textarea
              className="input-trip"
              value={notIncluded}
              onChange={(e) => setNotIncluded(e.target.value)}
              placeholder="Not Included Items"
              required
            ></textarea>
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <button className="button-add-trip" type="submit">
            {editId ? "Update Trip" : "Add Trip"}
          </button>
        </form>
      </div>

      <div className="trips-container">
        <h2 className="title-trip-result">Trips List</h2>
        {trips && trips.length > 0 ? (
          trips.map((trip) => (
            <div key={trip.id} className="trip-card">
              <div className="trip-info">
                <img
                  className="trip-image"
                  src={`${API_URL}/trips/${trip.id}/image`} // Ensure this matches the backend route
                  alt={trip.name}
                />
                <div>
                  <span className="trip-name">{trip.name}</span>
                  {/* <p className="trip-description">{trip.description}</p> */}
                </div>
              </div>
              <div className="trip-actions">
                <button onClick={() => handleEdit(trip)}>Edit</button>
                <button onClick={() => handleDelete(trip.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No trips found.</p>
        )}
      </div>
    </div>
  );
}

export default AddTrip;
