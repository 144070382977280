import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { FaBell, FaUserCircle, FaBars } from "react-icons/fa";
import "./AdminPanel.scss";
import logo from "../../assets/dekat_logo.png";
import AddDestination from "../../Screens/Destination/AddDestination";
import AddPackage from "../../Screens/Package/AddPackage";
import AddTrip from "../../Screens/Trips/AddTrip";
import AddActivities from "../../Screens/Activities/AddActivities";
import AddTravel from "../../Screens/Travel/AddTravel";
import FilterTravels from "../../Screens/Travel/FilterTravels";
import SendNewsletter from "../../Screens/NewLetter/SendNewsletter";
import AddPhoto from "../../Screens/AddPhoto/AddPhoto";
import AddAlbum from "../../Screens/AddAlbum/AddAlbum";

function AdminPanel() {
  const handleLogout = () => {
    // Logic for logout
    localStorage.removeItem("auth");
    // Navigate to login page
  };

  const toggleSidebar = () => {
    // Logic to toggle the sidebar
    document.querySelector(".sidebar").classList.toggle("collapsed");
  };

  return (
    <div className="main-panel">
      <div className="admin-panel">
        <Sidebar />
        <div className="content">
          <div className="sticky-navbar">
            <div className="left-section">
              <button className="toggle-sidebar" onClick={toggleSidebar}>
                <FaBars />
              </button>
              <div className="logo-div">
                <img src={logo} className="logo-img" alt="Logo" />
              </div>
            </div>
            <div className="right-section">
              <span className="welcome-text">Dashboard</span>
              <FaBell className="icon" />
              <div className="user-profile">
                <FaUserCircle className="icon" />
                <div className="dropdown">
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          </div>

          <Routes>
            <Route path="add-destination" element={<AddDestination />} />{" "}
            <Route path="add-package" element={<AddPackage />} />{" "}
            <Route path="add-trip" element={<AddTrip />} />{" "}
            <Route path="add-activites" element={<AddActivities />} />{" "}
            <Route path="add-travel" element={<AddTravel />} />{" "}
            <Route path="add-travelfilter" element={<FilterTravels />} />{" "}
            <Route path="add-newsletter" element={<SendNewsletter />} />{" "}
            <Route path="add-album" element={<AddAlbum />} />{" "}
            <Route path="add-photo" element={<AddPhoto />} />{" "}
            {/* Add route for AddJob */}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
